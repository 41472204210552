import * as React from 'react';

import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { Contact, PropertyTaxServices } from '../components/landing';
import { Footer, GoogleNoIndex, PageTitle } from '../components/shared';

import { StaticImage } from 'gatsby-plugin-image';
import { useScreenInfo } from '../utils';

const FacebookOlsLandingPage: React.FunctionComponent = () => {
  const screen = useScreenInfo();

  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <div id="landing-hero">
        {!screen.isPhone && (
          <StaticImage
            className="bg-image"
            src="../images/section-backgrounds/hero-image.jpeg"
            alt="hero image"
            loading="eager"
            breakpoints={[750, 1080, 1366, 1920]}
          />
        )}
        <Container fluid="lg" className="content">
          <div className="logo-header">
            <StaticImage className="logo" src="../images/logos/ttp-logo-white.png" height={100} alt="logo image" placeholder="none" />
          </div>

          <Row>
            <Col md={8}>
              <h1>
                Want to Lower Your <br /> Property Taxes?
              </h1>

              <Button className="text-uppercase my-3" href="/signup/?r=facebook&s=1" style={{ width: 290 }}>
                <div className="fs-5">Sign Up Now</div>
              </Button>

              <div className="mb-3">We win fair tax assessments for Texas property owners whose assessed property value is too high.</div>
              <div className="mb-3">
                Each property is unique, so if you expect to reduce your property tax burden you will need custom analysis of your property, comprehensive
                analysis of your neighborhood, analysis of comparable properties, and a strategy when meeting with the central appraisal district.
              </div>
              <div className="mb-3">We make tax protests easy so you can avoid frustrating forms and waiting rooms.</div>
              <div className="mb-5">Act Now! The filing deadline is fast approaching!</div>

              <Stack direction="horizontal" gap={3} className="mb-4">
                <div style={{ minWidth: 100 }}>
                  <StaticImage src="../images/icons/why-choose-us-research.png" alt="research" />
                </div>
                <div>
                  <h3>Research Technology</h3>
                  Our property research software applies big-data algorithms to analyze millions of data records to gather the facts we need to prepare your tax
                  protest case.
                </div>
              </Stack>

              <Stack direction="horizontal" gap={3} className="mb-4">
                <div style={{ minWidth: 100 }}>
                  <StaticImage src="../images/icons/why-choose-us-support.png" alt="support" />
                </div>
                <div>
                  <h3>Support From Real People</h3>
                  Local, helpful professionals that work with thousands of real estate owners each year.
                </div>
              </Stack>
            </Col>
            <Col md={4} className="pb-4"></Col>
          </Row>
        </Container>
      </div>
      <PropertyTaxServices />
      <Contact />
      <Footer />
    </>
  );
};

export default FacebookOlsLandingPage;
